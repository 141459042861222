const REASON_OPTIONS = [
  { label: "Request an invite code", value: "Request an invite code" },
  { label: "Press Inquiry", value: "Press Inquiry" },
  { label: "Suggestion", value: "Suggestion" },
  { label: "General Support", value: "General Support" },
];

const ENVs = {
  local: "http://localhost:3000",
  dev: "https://dev-web.salesstream.ai",
  qa: "https://qa-web.salesstream.ai",
  prod: "https://web.salesstream.ai",
};

const CRMS = {
  GOOGLE_SHEETS: "google-sheets",
  BULLHORN: "bullhorn",
  HUBSPOT: "hubspot",
};

const CRM_LABELS = {
  [CRMS.BULLHORN]: "Bullhorn",
  [CRMS.HUBSPOT]: "HubSpot",
};

const CRM_OPTIONS = [
  { value: CRMS.BULLHORN, label: CRM_LABELS[CRMS.BULLHORN] },
  { value: CRMS.HUBSPOT, label: CRM_LABELS[CRMS.HUBSPOT] },
  { value: CRMS.GOOGLE_SHEETS, label: "Google Sheets" },
];

const DEFAULT_GROUP_OPTION = {
  value: "any",
  label: "Any",
};

const FAQ_QUESTIONS = {
  generalInfo: {
    title: "General Info",
    questions: [
      {
        title: "What is SalesStreamAI and its main function?",
        answer: [
          "SalesStreamAI is an advanced AI-driven platform dedicated to optimizing sales and marketing outreach. Our flagship product, Reach, is a Chrome extension that offers seamless integration with multiple CRMs and prominent email providers like Office365 and GSuite. This integration empowers users to craft and dispatch hyper-personalized emails en masse.",
        ],
      },
      {
        title: "Which CRMs are compatible with SalesStreamAI?",
        answer: [
          "We have successfully integrated with Bullhorn and HubSpot CRMs. As we continuously seek to expand our capabilities, we're working on incorporating more CRM platforms tailored for small to medium-sized enterprises. Please reach out if you'd like to recommend a CRM for future integration.",
        ],
      },
      {
        title: "Does SalesStreamAI only facilitate email communication?",
        answer: [
          "No. While our current support includes email and text, we're actively exploring expansions into AI-generated voice and video communication.",
        ],
      },
      {
        title: "How does the SalesStreamAI Chrome extension operate?",
        answer: [
          "Our extension bridges the gap between popular CRMs and email services, including Office365 and GSuite. Users can thus draft and send personalized emails directly from their browser, integrated with their CRM's functionalities.",
        ],
      },
      {
        title: "How does SalesStreamAI achieve email personalization?",
        answer: [
          "We leverage state-of-the-art generative AI, specifically Large Language Models (LLMs). By incorporating pertinent CRM data, we craft emails that resonate with each recipient, ensuring personalized communication.",
        ],
      },
      {
        title: "Can SalesStreamAI handle responses to incoming emails?",
        answer: [
          "Absolutely! Our system can automatically draft and send replies to incoming messages, guaranteeing timely and relevant communication.",
        ],
      },
      {
        title: "How secure is my CRM data with SalesStreamAI?",
        answer: [
          "Your data's security is our utmost priority. We adhere to rigorous encryption standards and employ advanced security measures to ensure your CRM data remains strictly confidential and is utilized exclusively for email customization.",
        ],
      },
      {
        title:
          "How can I integrate the SalesStreamAI Chrome extension with my email system?",
        answer: [
          "For Office365: [Provide setup details for Azure app and obtaining credentials.]",
          "For GSuite: [Provide setup steps and credential acquisition details.]",
        ],
      },
      {
        title:
          "What are the steps to integrate the SalesStreamAI Chrome extension with my CRM?",
        answer: [
          "[Provide a generalized answer or offer directions for each CRM. It might be helpful to consider directing them to specific guides or support for more tailored instructions.]",
        ],
      },
      {
        title: "What type of support is available for users?",
        answer: ["[Elaborate on support channels, response times, etc.]"],
      },
      {
        title: "How do I commence my journey with SalesStreamAI?",
        answer: [
          "Kickstart your experience by downloading the Reach Chrome extension from the Chrome Web Store. After a straightforward installation and CRM/email system integration, you're all set for an enhanced email outreach experience.",
        ],
      },
      {
        title: "What are the associated costs of using SalesStreamAI?",
        answer: [
          "[Provide a brief overview or direct them to a pricing page for detailed information.]",
        ],
      },
    ],
  },
  appUse: {
    title: "App Use",
    questions: [
      {
        title:
          "How does defining campaign goals in the SalesStreamAI campaign workflow benefit me?",
        answer: [
          "Campaign goals provide our AI with a clear directive on the desired recipient interaction and response. From common goals like scheduling calls or email replies to custom user-defined objectives, these guide the AI in crafting effective communication strategies from the initial outreach to subsequent responses.",
        ],
      },
      {
        title:
          "What role do sales strategies play in the SalesStreamAI campaign workflow?",
        answer: [
          "Leveraging our AI, we scrutinize CRM data relevant to the product or service you're promoting. This analysis results in a variety of potential sales strategies for your campaign, with each strategy shaping the overarching theme of AI-generated content. Users can either select from our recommendations or define a custom strategy that aligns with their vision.",
        ],
      },
      {
        title: "Is campaign scheduling a feature in SalesStreamAI?",
        answer: [
          "Yes, users can predetermine the execution date and time of their campaigns. [Further elucidate on the number of attempts, duration, and other relevant details.]I",
        ],
      },
    ],
  },
};

const EMAIL_REGEX = /^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\-]+(\.[\w%\+\-]+)+$/;

const NOTIFICATION_TYPES = {
  EMAIL_REPLY: "email_reply",
  GOAL_COMPLETION: "goal_completion",
  OAUTH_TOKEN_EXPIRED: "oauth_token_expired",
};

const NOTIFICATION_STATUSES = {
  NEW: "new",
  READ: "was read",
};

const LOGIN_PAGE_CONTENT_LIST = {
  LOGIN: "login",
  ASK_RESET_PASSWORD: "ask-reset-password",
  RESET_PASSWORD: "reset-password",
};

const CHROME_STORE_EXTENSION_ID = "dpncdmdnpgoigoehmpkpblhdjkcdpaih";

const CHROME_STORE_EXTENSION_ADDRESS =
  "https://chromewebstore.google.com/detail/salesstreamai-reach/dpncdmdnpgoigoehmpkpblhdjkcdpaih?hl=en&authuser=1";

const DEFAULT_VALUE_FOR_MODEL = `[DEFAULT]`;
const DEFAULT_VALUE_FOR_PROMPT_COMPANY = `[DEFAULT]`;

const USER_ROLES = {
  DEACTIVATED: "deactivated",
  UNPAID: "unpaid",
  INVITED: "invited",
  ADMIN: "admin",
  USER: "user",
  COMPANY_ADMIN: "company_admin",
};

const OAUTH_TYPES = {
  office365: "office365",
  gsuite: "gsuite",
};

const FSC_STEP_OFFSET_UNIT = {
  WEEK: "w",
  DAY: "d",
  HOUR: "h",
  MINUTE: "m",
};

const FSC_STEP_OFFSET_UNIT_LABEL = {
  [FSC_STEP_OFFSET_UNIT.WEEK]: "week",
  [FSC_STEP_OFFSET_UNIT.DAY]: "day",
  [FSC_STEP_OFFSET_UNIT.HOUR]: "hour",
  [FSC_STEP_OFFSET_UNIT.MINUTE]: "minute",
};

const FSC_STEP_STATUS = {
  PENDING: "Pending",
  ASSIGNED: "Assigned",
  COMPLETED: "Completed",
  SKIPPED: "Skipped",
  SUCCESS: "Success",
  OPT_OUT: "Opt out",
};

const FSC_STEP_AUTOMATION_LEVEL = {
  AUTOMATIC: "Automatic",
  AI_ASSISTED: "AI Assisted",
  MANUAL: "Manual",
};

const FSC_STEP_TYPE = {
  EMAIL: "Email",
  SMS: "SMS",
  LINKEDIN_CONNECTION_REQUEST: "LinkedIn Connection Request",
  LINKEDIN_POST_COMMENT: "LinkedIn Post Comment",
  LINKEDIN_MESSAGE_INMAIL: "LinkedIn Message (InMail)",
  LINKEDIN_POST_LIKE: "LinkedIn Post Like",
  PHONE_CALL: "Phone Call",
  MARKETING_EMAIL: "Marketing Email",
};

const INTEGRATION_TYPE = {
  EMAIL: "Email",
  SMS: "SMS",
};

const POST_INSTALL_EXTENSION_SRC_COOKIE_NAME = "src";
const POST_INSTALL_EXTENSION_SRC_COOKIE_VALUE = "ph";

const PREPARED_CAMPAIGN_NAME_LOCAL_STORAGE_KEY = "preparedCampaignName";

const FIRST_TIME_CAMPAIGN_ONBOARDING_USER_LIST_LOCAL_STORAGE_KEY =
  "firstTimeCampaignOnboardingUserList";

const FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS = {
  createCampaign: {
    text: 'You\'re now ready to create your first campaign.  Enter a campaign title and click "Create Campaign" to give it a shot',
    offset: { top: "28%", left: "0%" },
    name: "createCampaign",
  },
  campaignOverview: {
    text: "Select the goal of your first campaign or create one if of your own.  Reach will use this as the main focus of your messages",
    offset: { top: "35%", right: "8%" },
    name: "campaignOverview",
  },
  campaignType: {
    text: 'Select a campaign type.  If the existing types aren\'t right, click "Create a new Campaign Type" and enter your own',
    offset: { top: "19%", right: "8%" },
    name: "campaignType",
  },
  campaignDetails: {
    text: "Here, you'll give Reach all the relevant data for your campaign.  The more information you can provide here, the better your outreach messages will become",
    offset: { top: "38%", right: "8%" },
    name: "campaignDetails",
  },
  salesStrategy: {
    text: "Reach has come up with some message themes based on your campaign details.  Select the one you think resonates best",
    offset: { top: "19%", right: "8%" },
    name: "salesStrategy",
  },
  selectRecipients: {
    text: "Review the contacts you've selected and remove any you many want to exclude from your campaign",
    offset: { top: "35%", left: "0%" },
    name: "selectRecipients",
  },
  configureOptions: {
    text: "Now it's time to specify your campaign options.  Tell Reach when you'd like the messages sent or give it some additional instructions",
    offset: { top: "38%", right: "8%" },
    name: "configureOptions",
  },
  prepareCampaign: {
    name: "prepareCampaign",
  },
  preparingCampaign: {
    text: "Some preview messages for your campaign are being generated.  This process may take a minute, so feel free to close the Reach window while you wait",
    offset: { top: "15%", right: "8%" },
    name: "preparingCampaign",
  },
  preparedCampaignDetails: {
    text: "You're done! You can now Review the generated messages or Launch your campaign",
    offset: { top: "-30%", right: "4%" },
    name: "preparedCampaignDetails",
  },
};

const FIRST_TIME_CAMPAIGN_TYPE_BY_USERS_LS_KEY =
  "outreachFirstTimeCampaignTypeByUsers";

const LAST_LOGIN_METHOD_LS_KEY = "outreachLastLoginMethod";

const HUBSPOT_APP_INSTALL_URL = "/install-hubspot-app";
const TEMP_LOCAL_STORAGE_KEY_NAME_COOKIE_NAME = "tempLocalStorageKeyName-";
const TEMP_LOCAL_STORAGE_KEY_NAME_LIST_COOKIE_NAME = "localStorageKeyNameList";
const SOURCE_QUERY_PARAM = "source";
const SOURCE_QUERY_VALUE = "integration";
const COOKIE_OPTIONS_STRING = `SameSite=None; Secure; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;

const MULTI_LEVEL_TABS_LIST_SETTINGS_PAGE = [
  "integrations",
  "promptUtils",
  "admin",
  "emailOptions",
];

export {
  REASON_OPTIONS,
  FAQ_QUESTIONS,
  CRM_OPTIONS,
  DEFAULT_GROUP_OPTION,
  CRMS,
  CRM_LABELS,
  EMAIL_REGEX,
  ENVs,
  NOTIFICATION_TYPES,
  NOTIFICATION_STATUSES,
  LOGIN_PAGE_CONTENT_LIST,
  CHROME_STORE_EXTENSION_ID,
  CHROME_STORE_EXTENSION_ADDRESS,
  DEFAULT_VALUE_FOR_MODEL,
  DEFAULT_VALUE_FOR_PROMPT_COMPANY,
  USER_ROLES,
  OAUTH_TYPES,
  FSC_STEP_OFFSET_UNIT,
  FSC_STEP_OFFSET_UNIT_LABEL,
  FSC_STEP_STATUS,
  FSC_STEP_AUTOMATION_LEVEL,
  FSC_STEP_TYPE,
  INTEGRATION_TYPE,
  POST_INSTALL_EXTENSION_SRC_COOKIE_NAME,
  POST_INSTALL_EXTENSION_SRC_COOKIE_VALUE,
  PREPARED_CAMPAIGN_NAME_LOCAL_STORAGE_KEY,
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
  FIRST_TIME_CAMPAIGN_ONBOARDING_USER_LIST_LOCAL_STORAGE_KEY,
  FIRST_TIME_CAMPAIGN_TYPE_BY_USERS_LS_KEY,
  LAST_LOGIN_METHOD_LS_KEY,
  HUBSPOT_APP_INSTALL_URL,
  SOURCE_QUERY_PARAM,
  SOURCE_QUERY_VALUE,
  COOKIE_OPTIONS_STRING,
  TEMP_LOCAL_STORAGE_KEY_NAME_COOKIE_NAME,
  TEMP_LOCAL_STORAGE_KEY_NAME_LIST_COOKIE_NAME,
  MULTI_LEVEL_TABS_LIST_SETTINGS_PAGE,
};
