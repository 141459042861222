import React, { useState } from "react";
import { styled } from "styled-components";
import PromptFormPopup from "./PromptFormPopup";
import PromptTestFormPopup from "./PromptTestFormPopup";
import theme from "../../../theme";
import { MultiSelect } from "react-multi-select-component";
import {
  CRM_OPTIONS,
  DEFAULT_GROUP_OPTION,
  DEFAULT_VALUE_FOR_PROMPT_COMPANY,
  USER_ROLES,
} from "../../../utils/constants";
import ConnectBtn from "../../ConnectBtn";
import { intersection } from "lodash";
import { toSnakeCase } from "../../../utils/utils";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";
import { getObjectFromLocalStorage } from "../../../api/localStorage";

function PromptsContent({
  prompts,
  promptsTypes,
  promptsGroups,
  modelsOptions,
  companies,
  updatePrompt,
  setActivePrompt,
  deactivatePrompt,
  createPrompt,
  filters,
  setFilters,
  sort,
  setSort,
}) {
  const [activeIndex, setActiveIndex] = useState(null);
  const [currentPrompt, setCurrentPrompt] = useState(null);
  const [isPromptFormPopupOpen, setIsPromptFormPopupOpen] = useState(false);
  const [isCrmPopupOpen, setIsCrmPopupOpen] = useState(false);
  const [crm, setCRM] = useState([]);
  const userInfo = getUserFromLocalStorage();
  const company = getObjectFromLocalStorage("companyInfo").company;

  const isAdmin = userInfo.role === USER_ROLES.ADMIN;

  const selectedCrmOption = () => {
    return CRM_OPTIONS.filter((option) => crm.includes(option.value));
  };

  const onSelectCRM = (newCRM) => {
    if (newCRM.length > 1) {
      newCRM = newCRM.filter(({ value }) => !crm.includes(value));
    }
    setCRM(newCRM.map((currentCrm) => currentCrm.value));
  };

  const [isTestingPopupOpen, setIsTestingPopupOpen] = useState(false);

  const handleItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const isActiveDefault = (type) => {
    const activePrompt = prompts.find(
      (prompt) =>
        prompt.type === type &&
        Array.isArray(prompt.active) &&
        prompt.active.length > 0
    );
    return activePrompt === undefined;
  };

  const isCurrentPromptActive = (item) => {
    return (
      (Array.isArray(item.active) && item.active.length > 0) ||
      (!item.active && !item.id && isActiveDefault(item.type))
    );
  };

  const setCrmForSetActive = (event, promptData, crmData) => {
    event.preventDefault();
    setActivePrompt(promptData || currentPrompt, crmData || crm);
    setCurrentPrompt(null);
    setCRM([]);
  };

  const setDeactivatePrompt = (event, prompt) => {
    event.preventDefault();
    deactivatePrompt(prompt, prompt.crm);
  };

  const openCrmPopup = (event, prompt) => {
    event.preventDefault();
    if (prompt.crm.length === 1) {
      setCrmForSetActive(event, prompt, prompt.crm);
      return;
    }
    setCurrentPrompt(prompt);
    setIsCrmPopupOpen(true);
    return;
  };

  const closeChooseCrmPopup = () => {
    setIsCrmPopupOpen(false);
    setCurrentPrompt(null);
    setCRM([]);
    return;
  };

  const openUpdatePromptPopup = (event, prompt) => {
    event.preventDefault();
    event.stopPropagation();

    const formattedPrompt = Object.assign({}, prompt);
    const conditionalStepIndex = formattedPrompt.sequence.findIndex(
      (item) => item.role === "conditional"
    );
    if (
      formattedPrompt.sequence[conditionalStepIndex] &&
      !formattedPrompt.sequence[conditionalStepIndex].content
    ) {
      formattedPrompt.sequence[conditionalStepIndex].content =
        formattedPrompt.sequence[conditionalStepIndex].options;
      delete formattedPrompt.sequence[conditionalStepIndex].options;
    }

    setCurrentPrompt(formattedPrompt);
    setIsPromptFormPopupOpen(true);
  };

  const openTestPromptPopup = (event, prompt) => {
    event.preventDefault();
    setCurrentPrompt(prompt);
    setIsTestingPopupOpen(true);
  };

  const onClose = (event) => {
    event?.preventDefault();
    setCurrentPrompt(null);
    setIsPromptFormPopupOpen(false);
    setIsTestingPopupOpen(false);
  };

  const openCreatePromptPopup = (event) => {
    event.preventDefault();
    const defaultPrompt = prompts.find((item) => !item.id) || prompts[0];
    setCurrentPrompt({ ...defaultPrompt });
    setIsPromptFormPopupOpen(true);
  };

  const handleSavePrompt = (data) => {
    setIsPromptFormPopupOpen(false);
    if (!data.id) {
      return createPrompt(data);
    }
    updatePrompt(data);
  };

  const preparePromptName = (prompt) => (prompt.id ? prompt.title : "Default");

  const preparePromptType = (prompt) =>
    promptsTypes[prompt.type]?.title || prompt.type;

  const preparePromptCompany = (prompt) => {
    if (!isAdmin && prompt.companyId === company.id) {
      return company.name;
    }

    const companyName = prompt.companyId
      ? companies.find((o) => o.value === prompt.companyId)?.label
      : null;
    return companyName || DEFAULT_VALUE_FOR_PROMPT_COMPANY;
  };

  const preparePromptCrm = (prompt) => prompt.crm.sort().join(", ");

  const preparePromptActive = (prompt) => {
    const activeCrm =
      (Array.isArray(prompt.active) &&
        prompt.active.length > 0 &&
        prompt.active.sort().join(", ")) ||
      (!prompt.active &&
        !prompt.id &&
        isActiveDefault(prompt.type) &&
        prompt.crm.sort().join(", ")) ||
      "";
    return activeCrm ? "Yes" : "No";
  };

  const typesOptions = Object.keys(promptsTypes)
    .map((key) => ({
      value: key,
      label: promptsTypes[key]?.title || key,
      promptGroup: promptsTypes[key].promptGroup,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  function filterTypesOptionsBasedOnPromptGroup(typesOptions) {
    return typesOptions.filter(
      (option) =>
        filters.group.includes(DEFAULT_GROUP_OPTION.value) ||
        filters.group.includes(toSnakeCase(option.promptGroup))
    );
  }

  const getGroupsOptions = () => {
    const options = Object.keys(promptsGroups)
      .map((key) => ({
        value: promptsGroups[key].code,
        label: promptsGroups[key].name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    options.unshift(DEFAULT_GROUP_OPTION);

    return options;
  };

  const groupsOptions = getGroupsOptions();

  const companyOptions = [
    {
      value: DEFAULT_VALUE_FOR_PROMPT_COMPANY,
      label: DEFAULT_VALUE_FOR_PROMPT_COMPANY,
    },
    ...companies,
  ];

  const selectedFilterOption = (filterName, options) => {
    return options.filter((option) =>
      filters[filterName].includes(option.value)
    );
  };

  const onSelectFilter = (filterName, selOptions) => {
    setFilters((curFilters) => ({
      ...curFilters,
      [filterName]: selOptions.map((o) => o.value),
    }));
  };

  const onSelectGroupsFilter = (selOptions) => {
    setFilters((curFilters) => {
      const isDefaultGroupSelectedNow = selOptions.some(
        (selOption) => selOption.value === DEFAULT_GROUP_OPTION.value
      );
      const isDefaultGroupSelectedBefore = curFilters.group.includes(
        DEFAULT_GROUP_OPTION.value
      );
      const isSelectionEmpty = !selOptions.length; // Indicates if no option is selected.

      if (isDefaultGroupSelectedNow && !isDefaultGroupSelectedBefore) {
        // If the user has changed the selection and the default group is now selected,
        // but it was not selected earlier, then reset the group filters to the default value.
        return { ...curFilters, group: [DEFAULT_GROUP_OPTION.value] };
      } else if (isSelectionEmpty) {
        // If no options were selected at all, reset the group filters to the default value.
        return { ...curFilters, group: [DEFAULT_GROUP_OPTION.value] };
      } else {
        // In all other cases, when other options were selected, except for the default group,
        // filter the list of options, excluding the default group, and update the filters.
        const filteredOptions = selOptions.filter(
          (option) => option.value !== DEFAULT_GROUP_OPTION.value
        );
        return { ...curFilters, group: filteredOptions.map((o) => o.value) };
      }
    });
  };
  const shouldDisplayMultiSelectClearIcon = (filterName, defaultValue) => {
    return filters[filterName].includes(defaultValue) ? null : undefined;
  };

  const filteredPrompts = prompts.filter(
    ({ crm, type, companyId }) =>
      (!filters.company.length ||
        filters.company.includes(
          companyId || DEFAULT_VALUE_FOR_PROMPT_COMPANY
        )) &&
      (!filters.crm.length || intersection(filters.crm, crm).length) &&
      (!filters.type.length || filters.type.includes(type))
  );

  const withSort = filteredPrompts.length > 1;

  const sortedPrompts = withSort
    ? filteredPrompts.sort((a, b) => {
        if (!sort.sort) {
          return (
            preparePromptCompany(a).localeCompare(preparePromptCompany(b)) ||
            preparePromptCrm(a).localeCompare(preparePromptCrm(b)) ||
            preparePromptType(a).localeCompare(preparePromptType(b)) ||
            preparePromptName(a).localeCompare(preparePromptName(b))
          );
        }

        if (sort.order === "desc") {
          const tmp = a;
          a = b;
          b = tmp;
        }

        if (sort.sort === "company") {
          return preparePromptCompany(a).localeCompare(preparePromptCompany(b));
        }

        if (sort.sort === "crm") {
          return preparePromptCrm(a).localeCompare(preparePromptCrm(b));
        }

        if (sort.sort === "name") {
          return preparePromptName(a).localeCompare(preparePromptName(b));
        }

        if (sort.sort === "type") {
          return preparePromptType(a).localeCompare(preparePromptType(b));
        }

        if (sort.sort === "active") {
          return preparePromptActive(a).localeCompare(preparePromptActive(b));
        }

        return 0;
      })
    : filteredPrompts;

  const handleClickSort = (sortField) => {
    setSort((curSort) => {
      if (sortField === curSort.sort) {
        if (curSort.order === "asc") {
          return { ...curSort, order: "desc" };
        }
        return { sort: null, order: null };
      }
      return { sort: sortField, order: "asc" };
    });
  };

  return (
    <Content>
      <Filters>
        <Filter>
          <FilterLabel>Type</FilterLabel>
          <MultiSelect
            options={filterTypesOptionsBasedOnPromptGroup(typesOptions)}
            value={selectedFilterOption("type", typesOptions)}
            onChange={(selOptions) => onSelectFilter("type", selOptions)}
            labelledBy="Type"
            hasSelectAll={false}
            disableSearch={true}
            closeOnChangedValue={true}
            display="chip"
          />
        </Filter>
        {isAdmin && (
          <Filter>
            <FilterLabel>Group</FilterLabel>
            <MultiSelect
              options={groupsOptions}
              value={selectedFilterOption("group", groupsOptions)}
              onChange={(selOptions) => onSelectGroupsFilter(selOptions)}
              labelledBy="Group"
              hasSelectAll={false}
              disableSearch={true}
              closeOnChangedValue={true}
              display="chip"
              ClearSelectedIcon={shouldDisplayMultiSelectClearIcon(
                "group",
                DEFAULT_GROUP_OPTION.value
              )}
            />
          </Filter>
        )}
        <Filter>
          <FilterLabel>CRM</FilterLabel>
          <MultiSelect
            options={CRM_OPTIONS}
            value={selectedFilterOption("crm", CRM_OPTIONS)}
            onChange={(selOptions) => onSelectFilter("crm", selOptions)}
            labelledBy="CRM"
            hasSelectAll={false}
            disableSearch={true}
            closeOnChangedValue={true}
            display="chip"
          />
        </Filter>
        {isAdmin && (
          <Filter>
            <FilterLabel>Company</FilterLabel>
            <MultiSelect
              options={companyOptions}
              value={selectedFilterOption("company", companyOptions)}
              onChange={(selOptions) => onSelectFilter("company", selOptions)}
              labelledBy="Company"
              hasSelectAll={false}
              disableSearch={true}
              closeOnChangedValue={true}
              display="chip"
            />
          </Filter>
        )}
      </Filters>

      <OverflowContent>
        <Table>
          <TableHead>
            <TableRow $bgColor="#f3f2f2">
              <TableHeadData
                $padding="15px 5px 15px 15px"
                {...(withSort
                  ? {
                      $cursorPointer: true,
                      onClick: () => handleClickSort("name"),
                    }
                  : {})}
              >
                <TableHeadDataSort>
                  Name
                  {withSort && (
                    <SortIcon
                      src={`/images/sort-${
                        sort.sort === "name" ? `${sort.order}-` : ""
                      }icon.png`}
                      alt="sort"
                    />
                  )}
                </TableHeadDataSort>
              </TableHeadData>
              <TableHeadData
                {...(withSort
                  ? {
                      $cursorPointer: true,
                      onClick: () => handleClickSort("type"),
                    }
                  : {})}
              >
                <TableHeadDataSort>
                  Type
                  {withSort && (
                    <SortIcon
                      src={`/images/sort-${
                        sort.sort === "type" ? `${sort.order}-` : ""
                      }icon.png`}
                      alt="sort"
                    />
                  )}
                </TableHeadDataSort>
              </TableHeadData>
              <TableHeadData
                {...(withSort
                  ? {
                      $cursorPointer: true,
                      onClick: () => handleClickSort("active"),
                    }
                  : {})}
              >
                <TableHeadDataSort>
                  Active
                  {withSort && (
                    <SortIcon
                      src={`/images/sort-${
                        sort.sort === "active" ? `${sort.order}-` : ""
                      }icon.png`}
                      alt="sort"
                    />
                  )}
                </TableHeadDataSort>
              </TableHeadData>
              <TableHeadData
                {...(withSort
                  ? {
                      $cursorPointer: true,
                      onClick: () => handleClickSort("crm"),
                    }
                  : {})}
              >
                <TableHeadDataSort>
                  CRM
                  {withSort && (
                    <SortIcon
                      src={`/images/sort-${
                        sort.sort === "crm" ? `${sort.order}-` : ""
                      }icon.png`}
                      alt="sort"
                    />
                  )}
                </TableHeadDataSort>
              </TableHeadData>
              <TableHeadData
                {...(withSort
                  ? {
                      $cursorPointer: true,
                      onClick: () => handleClickSort("company"),
                    }
                  : {})}
              >
                <TableHeadDataSort>
                  Company
                  {withSort && (
                    <SortIcon
                      src={`/images/sort-${
                        sort.sort === "company" ? `${sort.order}-` : ""
                      }icon.png`}
                      alt="sort"
                    />
                  )}
                </TableHeadDataSort>
              </TableHeadData>
              <TableHeadData>
                <TableHeadDataSort>Actions</TableHeadDataSort>
              </TableHeadData>
            </TableRow>
          </TableHead>
          <TableBody>
            <React.Fragment>
              {!sortedPrompts.length ? (
                <TableRow>
                  <EmptyTableData colSpan={100}>
                    No prompts to show
                  </EmptyTableData>
                </TableRow>
              ) : (
                sortedPrompts.map((prompt, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow
                        $cursor={"pointer"}
                        $borderBottom={`1px solid ${theme.colors.divider_color}`}
                        key={index}
                        onClick={() => handleItemClick(index)}
                      >
                        <TableData $padding="15px 5px 15px 15px">
                          {preparePromptName(prompt)}
                        </TableData>
                        <TableData>{preparePromptType(prompt)}</TableData>
                        <TableData>{preparePromptActive(prompt)}</TableData>
                        <TableData>{preparePromptCrm(prompt)}</TableData>
                        <TableData>{preparePromptCompany(prompt)}</TableData>
                        <TableData>
                          <PromptActions>
                            {isAdmin && !isCurrentPromptActive(prompt) ? (
                              <PromptEditBtn
                                className="button button-primary"
                                onClick={(event) => openCrmPopup(event, prompt)}
                              >
                                Set active
                              </PromptEditBtn>
                            ) : prompt.type.startsWith("compliance_") ? (
                              <PromptEditBtn
                                className="button button-primary"
                                onClick={(event) =>
                                  setDeactivatePrompt(event, prompt)
                                }
                              >
                                Deactivate
                              </PromptEditBtn>
                            ) : null}
                            {prompt.id &&
                              (isAdmin || prompt.companyId === company.id) && (
                                <PromptEditBtn
                                  className="button button-primary"
                                  onClick={(event) =>
                                    openUpdatePromptPopup(event, prompt)
                                  }
                                >
                                  Edit
                                </PromptEditBtn>
                              )}
                            <PromptEditBtn
                              className="button button-primary"
                              onClick={(event) =>
                                openTestPromptPopup(event, prompt)
                              }
                            >
                              Test
                            </PromptEditBtn>
                          </PromptActions>
                        </TableData>
                      </TableRow>

                      {index === activeIndex && (
                        <TableRow>
                          <PromptDetails colSpan="100">
                            {prompt.sequence.map((sequence, index) => (
                              <PromptTemplate key={index}>
                                <span>{sequence.role}:</span>&nbsp;
                                {sequence.content && sequence.content}
                                {sequence.options &&
                                  sequence.options.join(", ")}
                                {sequence.condition &&
                                  " (" + sequence.condition + ")"}
                              </PromptTemplate>
                            ))}
                          </PromptDetails>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })
              )}
            </React.Fragment>
          </TableBody>
        </Table>
      </OverflowContent>

      <CreatePromptBtn onClick={(event) => openCreatePromptPopup(event)}>
        Create prompt
      </CreatePromptBtn>
      {isCrmPopupOpen && (
        <ChooseCrmPopup>
          <div>
            <h5>Choose CRM</h5>
            <MultiSelect
              options={CRM_OPTIONS}
              value={selectedCrmOption()}
              onChange={onSelectCRM}
              labelledBy="CRM"
              hasSelectAll={false}
              disableSearch={true}
              closeOnChangedValue={true}
              display="chip"
            />
          </div>
          <CloseIcon className="close-icon" onClick={closeChooseCrmPopup}>
            x
          </CloseIcon>
          <ConnectBtn
            label="Set active"
            onClick={(event) => setCrmForSetActive(event)}
            disabled={selectedCrmOption().length === 0}
          />
        </ChooseCrmPopup>
      )}

      {isPromptFormPopupOpen && (
        <PromptFormPopup
          typesOptions={typesOptions}
          modelsOptions={modelsOptions}
          companies={companies}
          promptsTypes={promptsTypes}
          prompt={currentPrompt}
          onSave={handleSavePrompt}
          handleClose={onClose}
        />
      )}
      {isTestingPopupOpen && (
        <PromptTestFormPopup prompt={currentPrompt} handleClose={onClose} />
      )}
    </Content>
  );
}

export default PromptsContent;

const Content = styled.div`
  height: calc(100% - 50px);
`;

const PromptDetails = styled.td`
  background-color: #f6f6f5;
  border-radius: 5px;
  font-weight: 300;
  line-height: 20.82px;
  padding: 15px;
`;

const PromptTemplate = styled.div`
  font-size: 12px;
  font-family: "AlbertSansThin", sans-serif;
`;

const PromptEditBtn = styled.div`
  color: ${({ theme }) => theme.colors.saturated_purple};
  cursor: pointer;
  margin: 5px 0;
`;

const PromptActions = styled.div`
  align-self: end;
`;

const CreatePromptBtn = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.saturated_purple};
  height: 40px;
  padding: 0 25px;
  border-radius: 5px;
  border: none;
  font-weight: 800;
  margin-top: 20px;
`;

const OverflowContent = styled.div`
  overflow-y: auto;
  height: calc(100% - 65px - 88px);
`;

const TableHeadData = styled.th`
  &,
  * {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-size: 11px;
    color: #6d6d6d;
  }
  ${({ $padding }) =>
    $padding ? `padding: ${$padding}` : "padding: 15px 5px"};
  ${({ $width }) => ($width ? `width: ${$width}` : "")};
  font-weight: 800;
  ${({ $cursorPointer }) => ($cursorPointer ? "cursor: pointer" : "")};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

const TableBody = styled.tbody`
  background-color: #fbfbfb;
`;

const TableHead = styled.thead`
  text-align: left;
  position: sticky;
  top: -1px;
  z-index: 10;
`;

const TableRow = styled.tr`
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor};` : "")}
  height: 22px;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

const TableData = styled.td`
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth}` : "")};
  ${({ $padding }) =>
    $padding ? `padding: ${$padding}` : "padding: 15px 5px"};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  font-size: 13px;
  font-weight: 400;
  word-wrap: break-word;
`;

const EmptyTableData = styled(TableData)`
  text-align: center;
  opacity: 0.75;
`;

const ChooseCrmPopup = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 5px;
  height: 300px;
  width: 300px;
  top: calc((100% - 300px) / 2);
  left: calc((100% - 300px) / 2);
  border: 1px solid ${({ theme }) => theme.colors.divider_color};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 11;
  h5 {
    margin-bottom: 20px;
  }
`;

const CloseIcon = styled.span`
  content: "x";
  cursor: pointer;
  position: fixed;
  top: calc((100% - 320px) / 2);
  right: calc((100% - 320px) / 2);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
`;

const Filters = styled.div`
  margin-bottom: 25px;
  display: flex;
  column-gap: 25px;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  .multi-select .dropdown-content {
    z-index: 11;
  }
`;

const FilterLabel = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const TableHeadDataSort = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

const SortIcon = styled.img`
  width: 11px;
`;
